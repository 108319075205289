export interface RequestContext {
  tenantId?: string;
  shopId?: string;
  tableId?: string;
}

const decode = (value: string): RequestContext => {
  const fullString = `0000000000000${value}`.slice(-13);
  const tableId = fullString.substr(0, 4);
  const shopId = fullString.substr(4, 4);
  const tenantId = fullString.substr(8, 5);
  return { tenantId, shopId, tableId };
};

const tryParseBase36 = (value: string): RequestContext | null => {
  if (value.length <= 5 || value.length >= 10) return null;

  const base36 = parseInt(value, 36);
  if (!Number.isFinite(base36)) return null;

  return decode(base36.toString());
};

const guessLocationFromSearchParams = (
  params: URLSearchParams
): RequestContext | null => {
  // eslint-disable-next-line no-restricted-syntax
  for (const [key, value] of params) {
    if (value?.length)
      // eslint-disable-next-line no-continue
      continue;

    const location = tryParseBase36(key);
    if (location !== null) return location;
  }

  return null;
};

export const urlParamsToObject = (urlParams: string): RequestContext => {
  let base36 = parseInt(urlParams.substr(1), 36);
  if (urlParams.length > 5 && urlParams.length < 10 && base36)
    return decode(base36.toString());

  const query = new URLSearchParams(urlParams);
  if (!query.has('locationId')) {
    const location = guessLocationFromSearchParams(query);
    return location ?? {};
  }

  const locationId = query.get('locationId');
  if (locationId == null || locationId.length === 0) return {};

  base36 = parseInt(locationId, 36);
  if (!base36) return {};

  return decode(base36.toString());
};

export const urlParams: RequestContext =
  typeof window !== 'undefined'
    ? urlParamsToObject(window.location.search)
    : {};

export const augmentUrlRoute = (augment: string) => {
  return `${augment}/${window.location.search}`;
};
