import * as React from 'react';
import { Provider } from 'use-http';
import { useRequestContext } from '../hooks';

interface FetchProviderProps {
  children: React.ReactNode;
}

interface FetchProviderWrapperProps {
  element: React.ReactNode;
}

function FetchProvider({ children }: FetchProviderProps) {
  const { shopId, tableId, tenantId } = useRequestContext();

  const url = `${process.env.API_URL}/api`;
  const headers = {
    TenantId: tenantId || '',
    ShopId: shopId || '',
    TableId: tableId || ''
  };

  return (
    <Provider url={url} options={{ headers }}>
      {children}
    </Provider>
  );
}

export default function FetchProviderWrapper({
  element
}: FetchProviderWrapperProps) {
  return <FetchProvider>{element}</FetchProvider>;
}
