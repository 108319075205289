import { useEffect, useState } from 'react';
import {
  RequestContext,
  urlParams,
  urlParamsToObject
} from '../utils/urlUtils';

export default function useRequestContext(): RequestContext {
  const [context, setContext] = useState<RequestContext>(urlParams);

  useEffect(() => {
    if (typeof window === 'undefined') return;

    const newContext = urlParamsToObject(
      typeof window !== 'undefined' ? window.location.search : ''
    );

    setContext(newContext);
  }, [typeof window !== 'undefined' ? window.location.search : '', setContext]);

  return context;
}
